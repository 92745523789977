import React, {useEffect, useState} from 'react';
import {getByFetch} from '../../helpers/api';
import {DATE_FORMAT, UNAUTHORIZED_STATUS_CODE} from '../../helpers/constants';
import {GET_USER_ORDER_HISTORY} from '../../helpers/endpoints';
import {navigateToHome} from '../../helpers/navigation';
import 'dayjs/locale/es';
import dayjs from 'dayjs';

const History = () => {
  const [histories, setHistories] = useState([]);

  async function fetchHistory() {
    const response = await getByFetch(GET_USER_ORDER_HISTORY);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setHistories(data);
    } else {
      navigateToHome();
    }
  }

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="history-container d-flex flex-column">
      <div className="mt-7 d-flex flex-column">
        <h2 className="px-1">IZIPay History</h2>

        {histories.map((history, i) =>

          <div className="px-1 mt-3" key={i}>
            <div className='d-flex justify-content-between'>
              <h6>#{history.name.toUpperCase()}</h6>
              {history.orderType === 'purchase' ?
                <h6>{history.volume ? `${history.volume}ml ${history.temperature}` : '-'}</h6> : ``
              }
            </div>
            <div className='d-flex justify-content-between'>
              <small>{dayjs(new Date(history.date)).format(DATE_FORMAT)}</small>
              {history.orderType === 'topup' ?
                <h6 className='text-success'>Rp {history.value}</h6> : ``
              }
            </div>
            <hr className="my-0" />
          </div>,

        )}

      </div>
    </div>
  );
};
export default History;
